<template>
  <div id="page-content-wrapper">
    <Header title="Queue Groups (Telephony)"/>
    <div id="page-content">
      <div class="text-left pb-4">
          <b-button
          @click.prevent="addQueueGroup"
           class=""
           size="sm"
           v-if="isWxccDashboardAdmin"
           title="Add Queue Group"
          >+ Add Queue Group</b-button>
          </div>
      <b-row>
            <b-col cols="12" sm="12" md="12" lg="12" xl="10">
              <b-spinner v-if="loading" label="Loading..."></b-spinner>
              <b-table
                striped
                hover
                bordered
                small
                responsive
                sticky-header="900px"
                :items="queuegroups"
                :fields="fields"
                class="cy-table3"
                sort-by="id"
              >
                <template #cell(queues)="row">
                  {{allQueueGroups[row.item.id].queues.join(', ')}}
                </template>
                <template #cell(actions)="row">
                  <b-button
                    v-if="isWxccDashboardAdmin"
                    size="sm"
                    :to="{ path: `/wxcc/editqueuegroup/${row.item.id}` }"
                    :title='"Edit Queue Group "+row.item.name'
                    class="mr-1"
                    variant="warning"
                  ><font-awesome-icon icon='edit'/> Edit Queue Group</b-button>
                  <b-button
                    v-if="isWxccDashboardAdmin"
                    size="sm"
                    @click.prevent="deleteQueueGroup(row.item.id, row.item.name)"
                    :title='"Delete "+row.item.name'
                    class="mr-1"
                    variant="danger"
                  ><font-awesome-icon icon='trash-alt'/> Delete</b-button>
                </template>
              </b-table>
            </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import GraphqlService from '../service/GraphqlService'
import { mapGetters } from 'vuex'
import Header from '@/modules/shared/components/elements/Header'

export default {
  data () {
    return {
      queues: [],
      allQueues: {},
      allQueueIds: [],
      loading: true,
      allQueueGroups: {},
      allQueueGroupIds: [],
      queuegroups: [],
      fields: [
        // { key: 'id', label: 'id' },
        { key: 'name' },
        { key: 'queues' },
        { key: 'actions', label: 'Actions' }
      ]
    }
  },
  mounted () {
    this.$store.commit('auth/setShowNav', true)
    this.getQueueGroups()
  },
  methods: {
    async getQueueGroups () {
      this.loading = true
      const allqueues = await GraphqlService.getAlQueues(this.$store)
      this.allQueues = allqueues.reduce((prev, curr) => {
        prev[curr.id] = `${curr.name} [${curr.channelType.toLowerCase()} - ${curr.queueType.toLowerCase()}]`
        return prev
      }, {})
      allqueues.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        if (b.name.toLowerCase() > a.name.toLowerCase()) return -1
        return 0
      })
      allqueues.forEach(q => {
        this.allQueueIds.push(q.id)
      })
      const allqueuegroups = await GraphqlService.getQueueGroups(this.$store, '*', true)
      console.log('allqueuegroups', allqueuegroups)
      this.allQueueGroups = allqueuegroups.reduce((prev, curr) => {
        prev[curr.id] = {
          desc: `${curr.name}`,
          queues: curr.queueids.reduce((p, c) => {
            p.push(`${this.allQueues[c].replace(/\s*\[.*?\]\s*/, '')}`)
            return p
          }, [])
        }
        return prev
      }, {})
      allqueuegroups.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        if (b.name.toLowerCase() > a.name.toLowerCase()) return -1
        return 0
      })
      allqueuegroups.forEach(q => {
        this.allQueueGroupIds.push(q.id)
      })
      this.queuegroups = allqueuegroups
      this.loading = false
    },
    async addQueueGroup () {
      const addQG = await GraphqlService.addQueueGroup(this.$store)
      if (Object.hasOwn(addQG, 'errors')) {
        console.error(addQG.errors)
        this.$store.dispatch('global/DO_TOAST', { message: 'Failed to Add Queue Group ' + addQG.errors[0].message, variant: 'danger' })
      } else {
        this.$store.dispatch('global/DO_TOAST', { message: 'Added Queue Group changeme', variant: 'success' })
        this.getQueueGroups()
      }
    },
    async deleteQueueGroup (groupId, groupName) {
      this.$bvModal.msgBoxConfirm('Remove Queue Group [' + groupName + ']?', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        headerClass: 'text-danger',
        bodyClass: 'text-dark',
        hideHeaderClose: false,
        centered: true
      })
        .then(async value => {
          if (value) {
            const addQG = await GraphqlService.deleteQueueGroup(this.$store, groupId)
            if (Object.hasOwn(addQG, 'errors')) {
              console.error(addQG.errors)
              this.$store.dispatch('global/DO_TOAST', { message: 'Failed to Delete Queue Group [' + groupName + ']:' + addQG.errors[0].message, variant: 'danger' })
            } else if (addQG.id !== groupId) {
              console.error('error deleting')
              this.$store.dispatch('global/DO_TOAST', { message: 'Error Deleting Queue Group [' + groupName + ']', variant: 'danger' })
            } else {
              this.$store.dispatch('global/DO_TOAST', { message: 'Deleted Queue Group [' + groupName + ']', variant: 'success' })
              this.getQueueGroups()
            }
          }
        })
        .catch(err => {
          console.error(err)
          this.$store.dispatch('global/DO_TOAST', { message: `Error Deleting Queue Group ${err}`, variant: 'danger' })
        })
    }
  },
  computed: {
    ...mapGetters({
      roles: 'auth/roles'
    }),
    isWxccDashboardAdmin () {
      return this.roles.split(',').filter(e => e === 'wxcc_dashboard_admin').length === 1
    }
  },
  components: {
    Header
  }
}
</script>
<style scoped>
#page-content-wrapper {
  background-color: white;
  color: black;
}
.doscroll {
  overflow: auto;
}
</style>
