<template>
  <div id="longestWaiting" :class="callswaitingClass">{{longestWaiting | formatSec}}</div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      queues: 'wxcc/queues',
      lwvalueinfo: 'wxcc/lwvalueinfo',
      lwvaluewarning: 'wxcc/lwvaluewarning',
      lwvalueurgent: 'wxcc/lwvalueurgent'
    }),
    longestWaiting () {
      return this.queues.reduce((acc, val) => {
        if (val.longestwaiting > acc) acc = val.longestwaiting
        return acc
      }, 0)
    },
    callswaitingClass () {
      if (this.longestWaiting >= this.lwvalueurgent) return 'callsWatingVeryLong'
      if (this.longestWaiting >= this.lwvaluewarning) return 'callsWatingLong'
      else if (this.longestWaiting > this.lwvalueinfo) return 'callsWaiting'
      return ''
    }
  },
  filters: {
    formatSec (sec) {
      if (sec >= 3600) return new Date(sec * 1000).toISOString().substring(11, 19)
      return new Date(sec * 1000).toISOString().substring(14, 19)
    }
  }
}
</script>
<style scoped>
.callsWaiting {
  color: var(--lw-font-color-info);
  background-color: var(--lw-background-color-info);
}
.callsWatingLong {
  color: var(--lw-font-color-warning);
  background-color: var(--lw-background-color-warning);
}
.callsWatingVeryLong {
  color: var(--lw-font-color-urgent);
  background-color: var(--lw-background-color-urgent);
}
</style>
