<template>
  <GChart
    class="atea-chart"
    type="AreaChart"
    :data="hourlyWaitStats"
    :options="chartOptions"
  />
</template>
<script>
import { GChart } from 'vue-google-charts'
import { mapGetters } from 'vuex'

export default {
  name: 'WaitChart',
  props: {
  },
  data () {
    return {
      data: [],
      chartOptions: {
        chart: {
          title: 'Wait Times Today'
        },
        legend: {
          textStyle: {
            color: 'white'
          },
          position: 'top',
          alignment: 'center'
        },
        hAxis: {
          title: 'Hour of Day',
          titleTextStyle: {
            color: 'white'
          },
          textStyle: {
            color: 'white'
          },
          textPosition: 'out'
        },
        vAxis: {
          title: 'Wait Time (sec)',
          // format: '#',
          titleTextStyle: {
            color: 'white'
          },
          textStyle: {
            color: 'white'
          },
          gridlines: {
            count: -1,
            interval: 1,
            color: '#394046',
            units: {
              // seconds: { format: ['HH:mm:ss'] }
            }
          },
          minorGridlines: {
            // interval: 1
          },
          viewWindow: {
            min: 0
            // max: 10
          },
          minValue: 10
        },
        // isStacked: true,
        height: '100%',
        width: '100%',
        backgroundColor: '#3D444C',
        colors: ['crimson', 'green'],
        // seriesType: 'bars',
        // series: { 0: { type: 'line' } },
        chartArea: {
          // height: '80%',
          // width: '90%'
          // top: 48,
          // left: 48,
          // right: 16,
          // bottom: 48
        }
      }
    }
  },
  mounted () {
  },
  computed: {
    ...mapGetters({
      hourlyWaitStats: 'wxcc/hourlyWaitStats'
    })
  },
  components: {
    GChart
  }
}
</script>

<style>
.atea-chart {
  height: 100%;
}
.atea_full_height {
  height: 90%;
}
</style>
