<template>
  <div id="page-content-wrapper" class="doscroll">
    <Header :showBack="true" :title="'Edit Queue Group ['+queueGroup.name+']'"/>
    <div class="bv-example-row text-left pl-4 pt-4">
      <b-form-row>
        <b-col cols="4">
          <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="7"
            label="Queue Group Name"
            label-for="queueGroupName"
          >
          <b-input-group>
          <b-form-input v-model="queueGroup.name" id="queueGroupName"></b-form-input>
          <b-input-group-append>
            <b-button @click.prevent="saveQueueGroupName" size="sm" variant="warning"><font-awesome-icon icon='save'/> Save</b-button>
          </b-input-group-append>
          </b-input-group>
          </b-form-group>
          Queues <b-button size="sm" v-b-modal.csq-modal>+ Add Queue</b-button>  <span class="font-italic">(telephony only)</span>
          <b-spinner v-if="loading" label="Loading..."></b-spinner>
          <b-list-group class="pt-2">
            <b-list-group-item v-for="queue in queueGroup.queueids" :key="queue" class="bg-light">
              <b-button @click.prevent="deleteQueueFromQG(queue, allQueues[queue])" size="sm" variant="danger"><font-awesome-icon icon='trash-alt'/></b-button>
              {{allQueues[queue]}}
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-form-row>
    </div>
    <b-modal
    id="csq-modal"
    title="Add Queue"
    header-text-variant="primary"
    class="text-dark"
    size="lg"
  >
    <b-input-group>
    <b-form-input v-model="queuefilter" placeholder="Search"></b-form-input>
    <b-input-group-append>
      <b-input-group-text>
        <font-awesome-icon icon="times-circle" @click="queuefilter = ''"/>
      </b-input-group-text>
    </b-input-group-append>
    </b-input-group>
    <b-list-group class="text-dark">
          <b-list-group-item v-for="queue in freeQueues" :key="queue" class="bg-light">
            <b-button @click.prevent="addQueueToQG(queue, allQueues[queue])" size="sm" variant="success">+</b-button>
            {{allQueues[queue]}}
          </b-list-group-item>
        </b-list-group>
  </b-modal>
  </div>
</template>
<script>
import GraphqlService from '../service/GraphqlService'
import Header from '@/modules/shared/components/elements/Header'

export default {
  data () {
    return {
      groupId: '',
      queueGroup: {},
      queues: [],
      allQueues: {},
      allQueueIds: [],
      loading: true,
      queuefilter: ''
    }
  },
  async mounted () {
    this.groupId = this.$route.params.id
    const qg = await GraphqlService.getQueueGroups(this.$store, this.groupId, true)
    if (qg && qg.length === 1) {
      this.queueGroup = qg[0]
    }
    await this.getAllQueues()
    this.loading = false
  },
  methods: {
    async saveQueueGroupName () {
      try {
        const qg = await GraphqlService.saveQueueGroupName(this.$store, this.groupId, this.queueGroup.name)
        console.dir(qg)
        if (qg.id !== this.groupId) {
          this.$store.dispatch('global/DO_TOAST', { message: 'Error Saving Queue Group', variant: 'danger' })
        } else {
          this.$store.dispatch('global/DO_TOAST', { message: 'Saved Queue Group', variant: 'success' })
        }
      } catch (err) {
        console.error('error', err)
        this.$store.dispatch('global/DO_TOAST', { message: 'Error Saving Queue Group', variant: 'danger' })
      }
    },
    async getAllQueues () {
      this.allQueueIds = []
      const allqueues = await GraphqlService.getAlQueues(this.$store)
      this.allQueues = allqueues.reduce((prev, curr) => {
        if (curr.channelType.toLowerCase() === 'telephony') {
          prev[curr.id] = `${curr.name}`
        }
        return prev
      }, {})
      allqueues.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        if (b.name.toLowerCase() > a.name.toLowerCase()) return -1
        return 0
      })
      allqueues.forEach(q => {
        if (q.channelType.toLowerCase() === 'telephony') {
          this.allQueueIds.push(q.id)
        }
      })
    },
    async addQueueToQG (queueId, queueName) {
      try {
        const qg = await GraphqlService.addQueueToQueueGroup(this.$store, this.groupId, queueId)
        console.dir(qg)
        if (qg.id !== this.groupId) {
          this.$store.dispatch('global/DO_TOAST', { message: 'Error Adding Queue', variant: 'danger' })
        } else {
          this.queues.push(queueId)
          this.queueGroup.queueids.push(queueId)
          this.$store.dispatch('global/DO_TOAST', { message: 'Added Queue [' + queueName + ']', variant: 'success' })
        }
      } catch (err) {
        console.error('error', err)
        this.$store.dispatch('global/DO_TOAST', { message: 'Error Adding Queue', variant: 'danger' })
      }
    },
    async deleteQueueFromQG (queueId, queueName) {
      this.$bvModal.msgBoxConfirm('Remove Queue [' + queueName + ']?', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        headerClass: 'text-danger',
        bodyClass: 'text-dark',
        hideHeaderClose: false,
        centered: true
      })
        .then(async value => {
          if (value) {
            const qg = await GraphqlService.removeQueueFromQueueGroup(this.$store, this.groupId, queueId)
            if (qg.id !== this.groupId) {
              console.error('error deleting')
              this.$store.dispatch('global/DO_TOAST', { message: 'Error Removing Queue', variant: 'danger' })
            } else {
              this.$store.dispatch('global/DO_TOAST', { message: 'Removed Queue [' + queueName + ']', variant: 'success' })
              const qg = await GraphqlService.getQueueGroups(this.$store, this.groupId, true)
              if (qg && qg.length === 1) {
                this.queueGroup = qg[0]
              }
              await this.getAllQueues()
            }
          }
        })
        .catch(err => {
          console.error(err)
          this.$store.dispatch('global/DO_TOAST', { message: `Error Removing Queue ${err}`, variant: 'danger' })
        })
    }
  },
  computed: {
    freeQueues () {
      return this.allQueueIds.filter(e => {
        if (this.allQueues[e].toUpperCase().includes(this.queuefilter.toUpperCase()) && !this.queueGroup.queueids.includes(e)) return true
      })
    }
  },
  components: {
    Header
  }
}
</script>
<style scoped>
#page-content-wrapper {
  background-color: white;
  color: black;
}
.doscroll {
  overflow: auto;
}
</style>
