<template>
<div class="imgcontainer pt-1">
  <img v-if="logo != ''" :src="logo"/>
  <img v-else src="@/assets/Atea_logo.svg"/>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      logo: 'wxcc/logo'
    })
  }
}
</script>
<style scoped>
.imgcontainer {
  height: 100%;
  /* width: 100%; */
  vertical-align: middle;
  /* object-fit: contain; */
}
img {
  vertical-align: middle;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
</style>
