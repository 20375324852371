<template>
  <div id="ateafooter">
    <span class="footerTitle">WxCC Dashboard - Copyright &copy; {{year}} Ātea Systems Ltd. All rights reserved {{now}}</span>
    <span v-if="wsconnected" class="wsconnected">Websocket Connected.</span>
    <span v-if="!wsconnected" class="wsdisconnected">Websocket Disconnected</span>
    <span v-if="banner" class="banner">&nbsp;<a href="https://www.ateasystems.com/support/how-to-article/wxcc-dashboard-updates/" target="_blank">{{banner}} {{bannerTime}}</a>&nbsp;</span>
    <span @click.prevent="restore();" style="cursor: pointer; float: right; margin-right: 20px;">Restore layout </span>
    <span
      @click.prevent="saveLayout();"
      v-if="hasSavedLayout && !this.$route.query.at && isLoggedInAuth0 && (isWxccDashboardAdmin ||  allowedEditors.map(e => e.toLowerCase()).includes(userEmail.toLowerCase()) || allowedSupervisors.map(e => e.toLowerCase()).includes(userEmail.toLowerCase()))"
      style="cursor: pointer; float: right; margin-right: 20px;"
    >Save layout as default </span>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import GraphqlService from '@/modules/wxcc/service/GraphqlService'
import CodeService from '@/modules/wxcc/service/CodeService'

export default {
  data () {
    return {
      now: new Date().toString(),
      timer: null,
      layoutSaved: false,
      banner: '',
      bannerTime: ''
    }
  },
  props: {
    // wsconnected: {
    //   type: Boolean,
    //   default: false
    // }
  },
  computed: {
    ...mapGetters({
      wsconnected: 'wxcc/isWsConnected',
      dashId: 'wxcc/dashId',
      roles: 'auth/roles',
      isLoggedInAuth0: 'auth/isLoggedIn',
      allowedEditors: 'wxcc/allowedEditors',
      allowedSupervisors: 'wxcc/allowedSupervisors'
    }),
    isWxccDashboardAdmin () {
      return this.roles.split(',').filter(e => e === 'wxcc_dashboard_admin').length === 1
    },
    year () {
      return new Date().getFullYear()
    },
    hasSavedLayout () {
      if (this.layoutSaved) return true
      else if (localStorage.getItem('atea-grid-layout-' + this.dashId)) return true
      else return false
    },
    userEmail () {
      return this.$auth.user.email
    }
  },
  methods: {
    updateTime () {
      this.now = new Date().toString()
    },
    restore () {
      localStorage.removeItem('atea-grid-layout-' + this.dashId)
      localStorage.removeItem('atea-grid-sizes-' + this.dashId)
      location.reload()
    },
    saveLayout () {
      if (window.confirm('Are you sure you want to make this layout the default for all users? They will need to click "Restore layout" if they have already accessed the dashboard to have it pickup this new layout!')) {
        // this.$emit('saveGrid')
        GraphqlService.saveDefaultLayout(this.$store, this.dashId)
      }
    },
    setLayoutSaved (val) {
      this.layoutSaved = val
    }
  },
  mounted () {
    this.timer = setInterval(this.updateTime, 1000)
    CodeService.getBanner().then(e => {
      this.banner = e.banner
      // this.bannerTime = new Date(e.dateTime).toString()
      this.bannerTime = new Date(e.dateTime).toLocaleString()
    }).catch(e => console.log(e))
  },
  destroyed () {
    if (this.timer !== null) {
      clearInterval(this.timer)
    }
  }
}
</script>
<style scoped>
#ateafooter {
    color: white;
    font-size: 11px;
    position: fixed;
    left: 10px;
    bottom: 0;
    opacity: 0.6;
    width: 100%;
}
.footerTitle {
  float: left;
}
.wsconnected {
  background-color: green;
  float: left;
  margin-left: 10px;
}
.wsdisconnected {
  background-color: red;
  float: left;
  margin-left: 10px;
}
.banner {
  /* background-color: #ffc107; */
  float: left;
  margin-left: 10px;
}
span.banner a {
  text-decoration: none;
  color: #ffc107 !important;
}
</style>
