<template>
  <div id="page-content-wrapper">
    <Header title="Dashboards"/>
        <div id="page-content">
          <div class="text-left pb-4">
          <b-button
          @click.prevent="addDashboard"
           class=""
           size="sm"
           v-if="isWxccDashboardAdmin"
           title="Add Dashboard"
          >+ Add Dashboard</b-button>
          </div>
          <b-row styl="height: 100%;">
            <b-col cols="12" sm="12" md="12" lg="12" xl="10">
              <b-spinner v-if="loading" label="Loading..."></b-spinner>
              <b-table
                striped
                hover
                bordered
                small
                responsive
                sticky-header="700px"
                :items="dashboardsList"
                :fields="fields"
                class="cy-table3"
                sort-by="id"
                :current-page="currentPage"
                :per-page="perPage"
                id="dashboard-list"
              >
                <template #cell(view)="row">
                  <b-button
                    size="sm"
                    :to="{ path: `/wxcc/dashboard/${row.item.id}` }"
                    :title='"View "+row.item.name'
                    class="mr-1"
                    variant="info"
                  ><font-awesome-icon icon='eye'/> View</b-button>
                </template>
                <template #cell(public)="row">
                  <b-button
                    v-if="row.item.publicaccesstoken"
                    size="sm"
                    :to="{ path: `/wxcc/dashboard/${row.item.id}?at=${row.item.publicaccesstoken}` }"
                    :title='"View "+row.item.name'
                    class="mr-1"
                    variant="info"
                  ><font-awesome-icon icon='eye'/> View</b-button>
                </template>
                <template #cell(actions)="row">
                  <b-button
                    v-if="isWxccDashboardAdmin"
                    size="sm"
                    :to="{ path: `/wxcc/dashboardedit/${row.item.id}` }"
                    :title='"Edit Queues "+row.item.name'
                    class="mr-1"
                    variant="warning"
                  ><font-awesome-icon icon='edit'/> Edit Queues</b-button>
                   <b-button
                    v-if="isWxccDashboardAdmin || row.item.allowededitors.map(e => e.toLowerCase()).includes(userEmail.toLowerCase()) || row.item.allowedsupervisors.map(e => e.toLowerCase()).includes(userEmail.toLowerCase())"
                    size="sm"
                    :to="{ path: `/wxcc/dashboardeditlayout/${row.item.id}` }"
                    :title='"Edit Layout "+row.item.name'
                    class="mr-1"
                    variant="warning"
                  ><font-awesome-icon icon='edit'/> Edit Layout</b-button>
                   <b-button
                    v-if="isWxccDashboardAdmin || row.item.allowedsupervisors.map(e => e.toLowerCase()).includes(userEmail.toLowerCase())"
                    size="sm"
                    @click.prevent="generateNewPublicAccessToken(row.item.id, row.item.name)"
                    :title='"Generate New Public Link "+row.item.name'
                    class="mr-1"
                    variant="secondary"
                  ><font-awesome-icon icon='retweet'/> Regenerate Public Link</b-button>
                  <b-button
                    v-if="isWxccDashboardAdmin"
                    size="sm"
                    @click.prevent="cloneDashboard(row.item.id, row.item.name)"
                    :title='"Clone "+row.item.name'
                    class="mr-1"
                    variant="info"
                  ><font-awesome-icon icon='copy'/> Clone</b-button>
                  <b-button
                    v-if="isWxccDashboardAdmin"
                    size="sm"
                    @click.prevent="deleteDashboard(row.item.id, row.item.name)"
                    :title='"Delete "+row.item.name'
                    class="mr-1"
                    variant="danger"
                  ><font-awesome-icon icon='trash-alt'/> Delete</b-button>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="dashboard-list"
              ></b-pagination>
            </b-col>
          </b-row>
        </div>
    </div>
</template>
<script>
import GraphqlService from '../service/GraphqlService'
import { mapGetters } from 'vuex'
import Header from '@/modules/shared/components/elements/Header'

export default {
  data () {
    return {
      dashboards: [],
      fields: [
        { key: 'id', label: 'id' },
        { key: 'name' },
        { key: 'view', label: 'View' },
        { key: 'public', label: 'Public Link' },
        { key: 'actions', label: 'Actions' }
      ],
      loading: true,
      perPage: 15,
      currentPage: 0
    }
  },
  mounted () {
    this.$store.commit('auth/setShowNav', true)
    this.getDashboards()
  },
  watch: {
    async apiServer () {
      console.log('apiServer updated', this.apiServer)
      setTimeout(() => { this.getDashboards() }, 500)
      // this.getDashboards()
    }
  },
  methods: {
    async getDashboards () {
      this.loading = true
      this.dashboards = await GraphqlService.getDashboards(this.$store)
      this.loading = false
    },
    async addDashboard () {
      const addDash = await GraphqlService.addDashboard(this.$store)
      if (Object.hasOwn(addDash, 'errors')) {
        console.error(addDash.errors)
        this.$store.dispatch('global/DO_TOAST', { message: 'Failed to Add Dashboard ' + addDash.errors[0].message, variant: 'danger' })
      } else {
        this.$store.dispatch('global/DO_TOAST', { message: 'Added Dashboard changeme', variant: 'success' })
        this.getDashboards()
      }
    },
    async cloneDashboard (dashId, dashName) {
      this.$bvModal.msgBoxConfirm(`Clone Dashboard ${dashName}? (once cloned, edit your dashboard first and then wait a few minutes for the config to sync before viewing it)`, {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'info',
        okTitle: 'YES',
        cancelTitle: 'NO',
        headerClass: 'text-info',
        bodyClass: 'text-dark',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(async value => {
        if (value) {
          const dash = await GraphqlService.cloneDashboard(this.$store, dashId)
          if (dash.id === '' || dash.id === null || dash.id === dashId) {
            console.error('error cloning')
            this.$store.dispatch('global/DO_TOAST', { message: 'Error Cloning Dashboard', variant: 'danger' })
          } else {
            this.$store.dispatch('global/DO_TOAST', { message: `Cloned Dashboard ${dashName}, please edit your dashboard first and then wait a few minutes for the config to sync before viewing it`, variant: 'success' })
            this.getDashboards()
          }
        }
      })
        .catch(err => {
          console.error(err)
          this.$store.dispatch('global/DO_TOAST', { message: `Error Deleting Dashboard ${err}`, variant: 'danger' })
        })
    },
    async deleteDashboard (dashId, dashName) {
      this.$bvModal.msgBoxConfirm(`Delete Dashboard ${dashName}?`, {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        headerClass: 'text-danger',
        bodyClass: 'text-dark',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(async value => {
          if (value) {
            const dash = await GraphqlService.deleteDashboard(this.$store, dashId)
            if (dash.id !== dashId) {
              console.error('error deleting')
              this.$store.dispatch('global/DO_TOAST', { message: 'Error Deleting Dashboard', variant: 'danger' })
            } else {
              this.$store.dispatch('global/DO_TOAST', { message: `Deleted Dashboard ${dashName}`, variant: 'success' })
              this.getDashboards()
            }
          }
        })
        .catch(err => {
          console.error(err)
          this.$store.dispatch('global/DO_TOAST', { message: `Error Deleting Dashboard ${err}`, variant: 'danger' })
        })
    },
    async generateNewPublicAccessToken (dashId, dashName) {
      this.$bvModal.msgBoxConfirm(`Regenerate Public Link for ${dashId}-${dashName}? (existing link will stop working!)`, {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        headerClass: 'text-danger',
        bodyClass: 'text-dark',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(async value => {
          if (value) {
            const dash = await GraphqlService.generateNewPublicAccessToken(this.$store, dashId)
            if (dash.id !== dashId) {
              this.$store.dispatch('global/DO_TOAST', { message: 'Error Regenerating Public Link ', variant: 'danger' })
            } else {
              this.$store.dispatch('global/DO_TOAST', { message: `Regenerate Public Link for ${dashId}`, variant: 'success' })
              this.getDashboards()
            }
          }
        })
        .catch(err => {
          console.error(err)
          this.$store.dispatch('global/DO_TOAST', { message: `Error Regenerating Public Link ${err}`, variant: 'danger' })
        })
    }
  },
  computed: {
    ...mapGetters({
      roles: 'auth/roles',
      apiServer: 'wxcc/apiServer'
    }),
    isWxccDashboardAdmin () {
      return this.roles.split(',').filter(e => e === 'wxcc_dashboard_admin').length === 1
    },
    userEmail () {
      return this.$auth.user.email
    },
    dashboardsList () {
      if (this.isWxccDashboardAdmin) {
        return this.dashboards
      } else {
        return this.dashboards.filter(e => {
          return e.allowededitors.map(e => e.toLowerCase()).includes(this.userEmail.toLowerCase()) || e.allowedsupervisors.map(e => e.toLowerCase()).includes(this.userEmail.toLowerCase())
        })
      }
    },
    totalRows () {
      return this.dashboardsList.length
    }
  },
  components: {
    Header
  }
}
</script>
<style scoped>
#page-content-wrapper {
  background-color: white;
}
</style>
