<template>
  <div class="m-container">
    <div class="m-content" :style="{'-moz-animation': 'marquee ' + marqueeTime + 's linear infinite',
        '-webkit-animation': 'marquee ' + marqueeTime + 's linear infinite',
        animation: 'marquee ' + marqueeTime + 's linear infinite', color: marqueeFontColor}">{{ message }}</div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      message: 'wxcc/message',
      marqueeTime: 'wxcc/marqueeTime',
      marqueeFontColor: 'wxcc/marqueeFontColor'
    })
  },
  components: {
  }
}
</script>
<style>
.m-container {
  /* background-color: #1c87c9; */
  /* color: #fff; */
  padding: 5px;
  /* font-size: 48px; */
  /* vertical-align: middle; */
  height: 100%;
  width: 100%;
  display: flex;
  align-items:center;
  flex-wrap: wrap;
  margin: 0 auto;
  /* justify-content:center; */
  /* flex-shrink: 0; */
}
.m-content {
  /* -moz-animation: marquee 10s linear infinite;
  -webkit-animation: marquee 10s linear infinite;
  animation: marquee 10s linear infinite; */
  flex-basis: 100%;
  white-space: nowrap;
  /* vertical-align: middle; */
}
@-moz-keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@-webkit-keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes marquee {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
</style>
