<template>
    <div class="bg-light page-header-wrapper border-bottom" style="height: 60px; padding: 0.875rem 1.25rem; font-size: 1.2rem;">
        <div style="float: left;">
        <b-button
          v-if="showBack"
          size="sm"
          variant="dark"
          @click="goBack"
          v-b-tooltip.hover title="Go Back"
        ><font-awesome-icon icon="arrow-alt-circle-left" /></b-button> {{title}}
        </div>
    </div>
</template>
<script>
export default {
  name: 'Header',
  props: ['title', 'showBack', 'previousRoute'],
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
