import ReconnectingWebSocket from 'reconnecting-websocket'

const ONE_SECOND_IN_MS = 1000

export default {
  connectWs (store, dashId, callBackA, callBackQ, callBackM) {
    // console.dir(store)
    // let hostName = process.env.VUE_APP_HEROKU_URL
    let hostName = `${store.getters['wxcc/apiServer']}`
    if (location.protocol.indexOf('https') === 0) {
      hostName = hostName.replace('https', 'wss')
    } else {
      hostName = hostName.replace('http', 'ws')
    }
    let wsUrl = `${hostName}/api/ws/${store.getters['wxcc/wxccCustomerId']}?dashId=${dashId}`
    if (store.getters['wxcc/publicAccessToken'] !== '') {
      wsUrl = `${hostName}/papi/ws/${store.getters['wxcc/publicAccessToken']}?dashId=${dashId}`
    }
    // console.log('new ReconnectingWebSocket')
    var wsock = new ReconnectingWebSocket(
      wsUrl,
      ['access_token', localStorage.access_token],
      {
        debug: true,
        connectionTimeout: 8 * ONE_SECOND_IN_MS,
        maxReconnectionDelay: 10 * ONE_SECOND_IN_MS,
        minReconnectionDelay: 1.5 * ONE_SECOND_IN_MS,
        reconnectionDelayGrowFactor: 1
      }
    )
    // console.log('SAVE_WEBSOCKET', wsock)
    // store.dispatch('wxcc/SAVE_WEBSOCKET', wsock)
    wsock.addEventListener('open', () => {
      wsock.send('HELLO')
      store.dispatch('wxcc/SAVE_WEBSOCKET', wsock)
      store.commit('wxcc/setCallsWaiting', 10)
      store.commit('wxcc/setWebSocketConnected', true)
    })
    wsock.addEventListener('close', () => {
      store.dispatch('wxcc/SAVE_WEBSOCKET', null)
      store.commit('wxcc/setWebSocketConnected', false)
    })
    wsock.addEventListener('error', () => {
      store.dispatch('wxcc/SAVE_WEBSOCKET', null)
      store.commit('wxcc/setWebSocketConnected', false)
    })
    wsock.addEventListener('message', data => {
      const d = JSON.parse(data.data)
      let delay = 0
      if ('delayFactor' in d) {
        delay = d.delayFactor * 25
      }
      store.dispatch('wxcc/PROCESS_WEBSOCKET_MESSAGE', d)
      if (d.type === 2) {
        callBackA(delay)
        // callBackQ()
      } else if (d.type === 3) {
        const body = JSON.parse(d.body)
        store.commit('wxcc/PROCESS_WEBSOCKET_AGENTMESSAGE', body)
        // callBackA(delay)
      } else if (d.type === 4) {
        const body = JSON.parse(d.body)
        body.taskType = d.taskType
        store.commit('wxcc/PROCESS_WEBSOCKET_QUEUEMESSAGE', body)
        // TODO: disabled callback, may not work okay
        // callBackQ(delay)
      } else if (d.type === 5) {
        callBackM()
      }
    })
  }
}
