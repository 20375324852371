<template>
    <div id="page-content-wrapper">
        <div class="bg-light page-header-wrapper border-bottom" style="height: 60px; padding: 0.875rem 1.25rem; font-size: 1.2rem;">
            <div style="float: left;">
            Queues
            </div>
        </div>
        <div id="page-content">
            <b-table striped hover bordered small :items="queues" :fields="fields" class="cy-table3"></b-table>
        </div>
    </div>
</template>

<script>
import GraphqlService from '../service/GraphqlService'

export default {
  data () {
    return {
      queues: [],
      fields: ['name', 'channelType', 'queueType', 'routingType']
    }
  },
  mounted () {
    this.$store.commit('auth/setShowNav', true)
    this.getData()
  },
  methods: {
    async getData () {
      const loader = this.$loading.show({ loader: 'bars', color: '#A12321', isFullPage: false, container: this.$refs.formContainer2 })
      this.queues = await GraphqlService.getQueues(this.$store)
      loader.hide()
    }
  }
}
</script>
