<template>
  <div id="avgWait" :class="avgWaitClass">{{avgWait}}</div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
    }
  },
  methods: {
    formatSec (sec) {
      // console.log('formatSec', sec)
      if (sec >= 3600) return new Date(sec * 1000).toISOString().substring(11, 19)
      return new Date(sec * 1000).toISOString().substring(14, 19)
    }
  },
  computed: {
    ...mapGetters({
      queues: 'wxcc/queues'
    }),
    avgWait () {
      const avgW = this.queues.reduce((acc, val) => {
        acc.cp = acc.cp + val.tasksoffered
        acc.aw = acc.aw + (val.tasksoffered * val.avgenqueuetime)
        return acc
      }, {
        cp: 0,
        aw: 0
      })
      // console.log('avgW', avgW)
      if (avgW.cp > 0) {
        return this.formatSec(Math.round(avgW.aw / avgW.cp))
      }
      return this.formatSec(0)
    },
    avgWaitClass () {
      return ''
    }
  }
}
</script>
<style scoped>

</style>
