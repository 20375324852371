<template>
  <GChart
    class="atea-chart"
    type="ColumnChart"
    :data="hourlyQueueStats"
    :options="chartOptions"
  />
</template>
<script>
import { GChart } from 'vue-google-charts'
import { mapGetters } from 'vuex'

export default {
  name: 'Chart',
  props: {
  },
  data () {
    return {
      data: [
        ['Hour', 'Handled', 'Abandoned'],
        ['12am', 1, 0],
        ['1am', 2, 0],
        ['2am', 0, 0],
        ['3am', 5, 1],
        ['4am', 7, 2],
        ['5am', 8, 1],
        ['6am', 12, 2],
        ['7am', 14, 1],
        ['8am', 21, 2],
        ['9am', 25, 3],
        ['10am', 28, 1],
        ['11am', 21, 2],
        ['12pm', 30, 5],
        ['1pm', 22, 2],
        ['2pm', 22, 2],
        ['3pm', 22, 2],
        ['4pm', 22, 2],
        ['5pm', 22, 2],
        ['6pm', 12, 2],
        ['7pm', 8, 1],
        ['8pm', 6, 0],
        ['9pm', 0, 0],
        ['10pm', 0, 0],
        ['11pm', 0, 0]
      ],
      chartOptions: {
        chart: {
          title: 'Calls Today'
        },
        legend: {
          textStyle: {
            color: 'white'
          },
          position: 'top',
          alignment: 'center'
        },
        hAxis: {
          title: 'Hour of Day',
          titleTextStyle: {
            color: 'white'
          },
          textStyle: {
            color: 'white'
          },
          textPosition: 'out'
        },
        vAxis: {
          title: 'Calls',
          titleTextStyle: {
            color: 'white'
          },
          textStyle: {
            color: 'white'
          },
          gridlines: {
            count: -1,
            interval: 1,
            color: '#394046'
          },
          minorGridlines: {
            // interval: 1
          },
          viewWindow: {
            min: 0
            // max: 10
          },
          minValue: 10
        },
        isStacked: true,
        height: '100%',
        width: '100%',
        backgroundColor: '#3D444C',
        colors: ['green', 'crimson'],
        chartArea: {
          // height: '80%',
          // width: '90%'
          // top: 48,
          // left: 48,
          // right: 16,
          // bottom: 48
        }
      }
    }
  },
  mounted () {
  },
  computed: {
    ...mapGetters({
      hourlyQueueStats: 'wxcc/hourlyQueueStats'
    })
  },
  components: {
    GChart
  }
}
</script>

<style>
.atea-chart {
  height: 100%;
}
.atea_full_height {
  height: 90%;
}
</style>
