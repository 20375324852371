<template>
  <div id="totalCallsWaiting" :class="callswaitingClass">{{callsWaiting}}</div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      queues: 'wxcc/queues',
      cwvalueinfo: 'wxcc/cwvalueinfo'
    }),
    callsWaiting () {
      return this.queues.reduce((acc, val) => {
        acc = acc + val.callswaiting
        return acc
      }, 0)
    },
    callswaitingClass () {
      if (this.callsWaiting > this.cwvalueinfo) return 'callsWaiting'
      return ''
    }
  }
}
</script>
<style scoped>
.callsWaiting {
  color: var(--font-color-info);
  background-color: var(--background-color-info);
}
/* .callsWatingLong {
   color: var(--cw-font-color-warning);
   background-color: var(--cw-background-color-warnming);
} */
</style>
