<template>
  <div id="totalCallsAbandonedPct" :class="abandonedCallsClass">{{abandonedCalls}}%</div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      queues: 'wxcc/queues'
    }),
    abandonedCalls () {
      const ab = this.queues.reduce((acc, val) => {
        acc.a = acc.a + val.tasksabandoned
        // acc.o = acc.o + val.tasksoffered
        acc.o = acc.o + val.taskstotal
        return acc
      }, { a: 0, o: 0 })
      if (ab.o === 0) {
        return 100
      }
      return Math.round((ab.a / ab.o) * 100)
    },
    abandonedCallsClass () {
      return ''
    }
  }
}
</script>
<style scoped>

</style>
