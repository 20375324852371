<template>
 <div id='page-content-wrapper'>
  <Header title="WxCC Admin"/>
  <div id="page-content">
     <div class="text-left pb-4">
      <b-button @click.prevent='onSubmit' variant='danger'>Request WxCC API Access Tokens</b-button> (<b-badge>Note:</b-badge> only use this when directed by Atea)
     </div>
  </div>
 </div>
</template>

<script>
import CodeService from '@/modules/wxcc/service/CodeService'
import WebexService from '@/modules/wxcc/service/WebexService'
import Header from '@/modules/shared/components/elements/Header'
export default {
  data () {
    return {
      clientID: ''
    }
  },
  methods: {
    onSubmit (evt) {
      evt.preventDefault()
      window.location.href = WebexService.getAuthUrl(this.clientID)
    }
  },
  async mounted () {
    const ccid = await CodeService.getClientId(this.$store)
    this.clientID = ccid.client_id
  },
  components: {
    Header
  }
}
</script>
<style scoped>
#page-content-wrapper {
  background-color: white;
}
</style>
