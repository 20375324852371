<template>
  <div class="panel grid-stack-item ">
    <div class="grid-stack-item-content">
      <header class="panel-heading">
        <span class="font-btn btn-decrT" @click.prevent="decreaseT">--</span>
        <span class="font-btn btn-decr" @click.prevent="decrease">-</span>
        <span class="title">{{title}}</span>
        <span class="font-btn btn-incr" @click.prevent="increase">+</span>
        <span class="font-btn btn-incrT" @click.prevent="increaseT">++</span>
        <span style="float: left;">
          <span v-if="aicon">
            <span class="font-btn btn-decr" @click.prevent="decreaseI">-</span>
            <span class="grid-title">ICON</span>
            <span class="font-btn btn-incr" @click.prevent="increaseI">+</span>
          </span>
        </span>
        <span style="float: right;">
          <span v-if="wgrid" style="padding-left: 12px;">
            <span class="font-btn btn-decr" @click.prevent="decreaseG">-</span>
            <span class="grid-title">GRID</span>
            <span class="font-btn btn-incr" @click.prevent="increaseG">+</span>
          </span>
        </span>
      </header>
      <div :class="'panel-body ' + panelClass" :style="wstyle" :id="'widget-'+wid" :ref="wid">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
    }
  },
  props: {
    aicon: {
      type: String
    },
    wgrid: {
      type: String
    },
    wstyle: {
      type: String
    },
    wid: {
      type: String
    },
    title: {
      type: String
    },
    panelClass: {
      type: String
    },
    initDone: {
      type: Boolean
    }
  },
  mounted () {
    console.log('mounted dashboardwidget', this.initDone)
    if (this.initDone) {
      this.restore()
    }
    // setTimeout(() => { this.restore() }, 500)
    // this.$$nextTick(() => {
    //   this.restore()
    // })
    // this.restore()
  },
  computed: {
    ...mapGetters({
      dashId: 'wxcc/dashId'
    })
  },
  watch: {
    initDone (newDone, oldDone) {
      console.log('initDone', oldDone, newDone)
      if (!oldDone && newDone) {
        this.restore()
      }
    }
  },
  methods: {
    // TODO: document.querySelector(':root').style.setProperty('--num-agent-grid', 6)
    increase () {
      // console.dir('find', 'widget-' + this.wid)
      const a = document.getElementById('widget-' + this.wid)
      // console.log('element', a)
      // console.log('current fontsize', a.style.fontSize)
      const currSize = a.style.fontSize.replaceAll('em', '')
      // console.log('current size', currSize)
      const newSize = (parseFloat(currSize) + 0.1)
      // console.log('new size', newSize)
      a.style.fontSize = newSize + 'em'
      // console.log('new fontsize', a.style.fontSize)
      this.save()
    },
    increaseT () {
      // console.dir('find', 'widget-' + this.wid)
      const a = document.getElementById('widget-' + this.wid)
      // console.log('element', a)
      // console.log('current fontsize', a.style.fontSize)
      const currSize = a.style.fontSize.replaceAll('em', '')
      // console.log('current size', currSize)
      const newSize = (parseFloat(currSize) + 1.0)
      // console.log('new size', newSize)
      a.style.fontSize = newSize + 'em'
      // console.log('new fontsize', a.style.fontSize)
      this.save()
    },
    decrease () {
      const a = document.getElementById('widget-' + this.wid)
      const currSize = a.style.fontSize.replaceAll('em', '')
      a.style.fontSize = (parseFloat(currSize) - 0.1) + 'em'
      this.save()
    },
    decreaseT () {
      const a = document.getElementById('widget-' + this.wid)
      const currSize = a.style.fontSize.replaceAll('em', '')
      a.style.fontSize = (parseFloat(currSize) - 1.0) + 'em'
      this.save()
    },
    increaseG () {
      const gridSize = getComputedStyle(document.documentElement)
        .getPropertyValue(this.wgrid)
      const newSize = parseInt(gridSize) + 1
      document.querySelector(':root').style.setProperty(this.wgrid, newSize)
      this.save()
    },
    decreaseG () {
      const gridSize = getComputedStyle(document.documentElement)
        .getPropertyValue(this.wgrid)
      const newSize = parseInt(gridSize) - 1
      document.querySelector(':root').style.setProperty(this.wgrid, newSize)
      this.save()
    },
    increaseI () {
      const iconSize = getComputedStyle(document.documentElement)
        .getPropertyValue(this.aicon)
        .replaceAll('px', '')
      const newSize = parseInt(iconSize) + 2
      document.querySelector(':root').style.setProperty(this.aicon, newSize + 'px')
      this.save()
    },
    decreaseI () {
      const iconSize = getComputedStyle(document.documentElement)
        .getPropertyValue(this.aicon)
        .replaceAll('px', '')
      const newSize = parseInt(iconSize) - 2
      document.querySelector(':root').style.setProperty(this.aicon, newSize + 'px')
      this.save()
    },
    save () {
      let settings = {}
      if (localStorage.getItem('atea-grid-sizes-' + this.dashId)) {
        settings = JSON.parse(localStorage.getItem('atea-grid-sizes-' + this.dashId))
      }
      settings['widget-' + this.wid] = document.getElementById('widget-' + this.wid).style.fontSize
      if (this.wgrid) {
        settings[this.wgrid] = getComputedStyle(document.documentElement)
          .getPropertyValue(this.wgrid)
      }
      if (this.aicon) {
        settings[this.aicon] = getComputedStyle(document.documentElement)
          .getPropertyValue(this.aicon)
      }
      localStorage.setItem('atea-grid-sizes-' + this.dashId, JSON.stringify(settings))
      // this.$refs.dashboardFooter.setLayoutSaved(true)
    },
    restore () {
      this.$nextTick(function () {
        const DASH_ID = this.$route.params.id
        console.log('RESTORE', this.wid, DASH_ID)
        if (localStorage.getItem('atea-grid-sizes-' + DASH_ID)) {
          const settings = JSON.parse(localStorage.getItem('atea-grid-sizes-' + DASH_ID))
          console.log('SETTINGS', settings)
          // eslint-disable-next-line no-prototype-builtins
          if (settings.hasOwnProperty('widget-' + this.wid)) {
            console.log('found widget-', this.wid)
            document.getElementById('widget-' + this.wid).style.fontSize = settings['widget-' + this.wid]
            // this.$refs['widget-' + this.wid].style.fontSize = settings['widget-' + this.wid]
            console.log(`set fontsize for ${'widget-' + this.wid}`, settings['widget-' + this.wid])
          }
          // eslint-disable-next-line no-prototype-builtins
          if (settings.hasOwnProperty(this.wgrid)) {
            console.log('found wgrid ', this.wgrid)
            document.querySelector(':root').style.setProperty(this.wgrid, settings[this.wgrid])
          }
          // eslint-disable-next-line no-prototype-builtins
          if (settings.hasOwnProperty(this.aicon)) {
            console.log('found aicon ', this.aicon)
            document.querySelector(':root').style.setProperty(this.aicon, settings[this.aicon])
          }
        }
      })
    }
  }
}
</script>
<style scoped>

.panel {
  background: #272B30;
  /* margin: 6px; */
  /* border: 6px solid transparent ; */
}
.grid-stack-item-content {
  background: #3E444C;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}
.panel-heading {
    background: #32383E;
    color: white;
    text-align: center;
}
.panel-body {
  color: white;
  /* height: 90%; */
}
div.panel-body {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}
.font-btn:hover {
  opacity: 1;
}
.font-btn {
  display: inline-block;
  opacity: 0.1;
  width: 20px;
  height: 20px;
  background: grey;
}
.btn-incr {
  cursor: n-resize;
}
.btn-decr {
  cursor: s-resize;
}
.btn-incrT {
  cursor: n-resize;
  width: 25px;
}
.btn-decrT {
  cursor: s-resize;
  width: 25px;
}
.title {
  padding-left: 10px;
  padding-right: 10px;
}
.grid-title:hover {
  opacity: 0.9;
}
.grid-title {
  opacity: 0.1;
  padding-left: 10px;
  padding-right: 10px;
}
.atea-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
