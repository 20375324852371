<template>
  <div id="gos" :class="gosClass">{{gos}}%</div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      queues: 'wxcc/queues',
      gosvalueinfo: 'wxcc/gosvalueinfo',
      gosvaluewarning: 'wxcc/gosvaluewarning',
      gosvalueurgent: 'wxcc/gosvalueurgent'
    }),
    gos () {
      const avgW = this.queues.reduce((acc, val) => {
        acc.cp = acc.cp + val.tasksoffered
        acc.gos = acc.gos + (val.tasksoffered * val.gos)
        return acc
      }, {
        cp: 0,
        gos: 0
      })
      // console.log('gos', avgW)
      if (avgW.cp > 0) {
        return Math.round(avgW.gos / avgW.cp)
      }
      return 100
    },
    gosClass () {
      if (this.gos < this.gosvalueurgent) return 'gos-atea-red-orange'
      else if (this.gos < this.gosvaluewarning) return 'gos-atea-red'
      else if (this.gos < this.gosvalueinfo) return 'gos-atea-orange'
      return ''
    }
  }
}
</script>
<style scoped>
.gos-atea-orange {
  color: var(--gos-font-color-info);
  background-color: var(--gos-background-color-info);
}
.gos-atea-red {
  color: var(--gos-font-color-warning);
  background-color: var(--gos-background-color-warning);
}
.gos-atea-red-orange {
  color: var(--gos-font-color-urgent);
  background-color: var(--gos-background-color-urgent);
}
</style>
