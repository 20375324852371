<template>
  <div id="agents" class="agentgridcontainer">
    <div v-for="agent in sortedAgents" :key="agent.email" class="agentgriditem">
      <img class="agent agentgridIcon" :src="getBase64Icon(agent.state)" alt="agent icon"/><br>
      {{agent.firstname}} {{agent.lastname}} <span v-if="showAgentCallsHandled">[{{agent.taskshandled}}]</span><br/>
      <span v-if="!agent.reason || agent.state !== 'Not Ready'" :class="'agent agentState '+(agent.state)">{{agent.state}}</span><span v-if="agent.state === 'OnHold'"> - {{ agent.holdtime | formatSec }}</span><br v-if="!agent.reason || agent.state !== 'Not Ready'" />
      <span v-if="agent.reason && agent.state === 'Not Ready'">{{agent.reason}}<br/></span>
      {{agent.duration | formatSec}}
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import AgentIconService from '../../service/AgentIconService'
export default {
  name: 'AgentsGrid',
  data () {
    return {
    }
  },
  props: {
    data: {
      type: Array
    }
  },
  computed: {
    ...mapGetters({
      agents: 'wxcc/agents',
      agentSort: 'wxcc/agentSort',
      agentSort2: 'wxcc/agentSort2',
      agentSortDirection: 'wxcc/agentSortDirection',
      agentSortDirection2: 'wxcc/agentSortDirection2',
      showAgentCallsHandled: 'wxcc/showAgentCallsHandled',
      hideNR: 'wxcc/hideNR'
    }),
    sortedAgents () {
      let a = this.agents

      if (this.hideNR !== undefined && this.hideNR !== null && this.hideNR.length > 0 && this.hideNR[0] !== '') {
        a = a.filter(e => {
          if (this.hideNR.includes(e.reason)) return false
          return true
        })
      }
      return a.slice().sort((o1, o2) => {
        if (this.agentSortDirection === 'asc') {
          if (o1[this.agentSort] < o2[this.agentSort]) return -1
          if (o1[this.agentSort] > o2[this.agentSort]) return 1
        } else {
          if (o1[this.agentSort] > o2[this.agentSort]) return -1
          if (o1[this.agentSort] < o2[this.agentSort]) return 1
        }
        if (this.agentSort2 && this.agentSortDirection2) {
          if (this.agentSortDirection2 === 'asc') {
            if (o1[this.agentSort2] < o2[this.agentSort2]) return -1
            if (o1[this.agentSort2] > o2[this.agentSort2]) return 1
          } else {
            if (o1[this.agentSort2] > o2[this.agentSort2]) return -1
            if (o1[this.agentSort2] < o2[this.agentSort2]) return 1
          }
        } else {
          if (`${o1.firstName} ${o1.lastName}` < `${o2.firstName} ${o2.lastName}`) return -1
          if (`${o1.firstName} ${o1.lastName}` > `${o2.firstName} ${o2.lastName}`) return 1
        }
        return 0
      })
      // return a
    }
  },
  methods: {
    getBase64Icon (presence) {
      switch (presence) {
        case 'Available':
          return AgentIconService.getBase64Icon('agent_green')
        case 'Away':
          return AgentIconService.getBase64Icon('agent_blue')
        case 'Wrapup':
          return AgentIconService.getBase64Icon('agent_wrapup')
        case 'Not Ready':
          return AgentIconService.getBase64Icon('agent_red')
        case 'Ringing':
          return AgentIconService.getBase64Icon('agent_redorange')
        case 'Talking':
          return AgentIconService.getBase64Icon('agent_orange')
        case 'OnHold':
          return AgentIconService.getBase64Icon('agent_orange')
        case 'Logout':
          return AgentIconService.getBase64Icon('agent_blue')
        default:
          return AgentIconService.getBase64Icon('agent_green')
      }
      // return AgentIconService.getBase64Icon(icon)
    }
  },
  filters: {
    formatSec (sec) {
      if (sec >= 3600) return new Date(sec * 1000).toISOString().substring(11, 19)
      return new Date(sec * 1000).toISOString().substring(14, 19)
    }
  }
}
</script>
<style>
:root {
  --num-agent-grid: 6;
  --agent-grid-icon-width: 50px;
}
.Available {
  color: green;
}
.Wrapup {
  color: red;
}
.Ringing {
  color: orange;
}
.Talking {
  color: orange;
}
.agentgridcontainer {
    display: grid;
    grid-template-columns: repeat(var(--num-agent-grid), 1fr);
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    grid-auto-rows: 1fr;
}
 .agentgridcontainer > div {
    border: 1px solid #32383E;
    text-align: center;
}
.agentgriditem {
    border-radius: 5px;
    padding: 5px;
}
.agentgriditem img {
    width: var(--agent-grid-icon-width);
}
/* .agentIcon {
    width: 22px;
}

.agentIcon img {
    width: var(--agentIconHeight);
    height: var(--agentIconHeight);
} */
#agents  {
  padding: 10px;
}
</style>
