<template>
  <div id="queues" class="page-content-wrapper queuesDiv">
    <b-table
      small
      :items="sortedQueues"
      :fields.sync="visibleFields"
      no-local-sorting
      class="cy-table3 text-white"
    >
      <template #cell(availableagents)="row">
       <span v-if="row.item.routingtype === 'SKILLS_BASED'" class="text-white">n/a</span>
       <span v-else>{{row.item.availableagents}}</span>
      </template>
    </b-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Queues',
  data () {
    return {
      fields: [
        {
          key: 'name',
          visible: true,
          tdClass: this.queueNameClass
        },
        {
          key: 'callswaiting',
          label: 'Calls Waiting',
          tdClass: this.callswaitingClass,
          visible: true
        },
        {
          key: 'longestwaiting',
          label: 'Longest Waiting',
          tdClass: this.longestwaitingClass,
          formatter: this.formatSec,
          visible: true
        },
        {
          key: 'loggedinagents',
          label: 'Logged In Agents',
          visible: true
        },
        {
          key: 'availableagents',
          label: 'Available Agents',
          tdClass: this.availableAgentsClass,
          visible: true
          // thClass: 'd-none'
        },
        {
          key: 'connectedagents',
          label: 'Talking Agents',
          visible: true
        },
        {
          key: 'tasksoffered',
          label: 'Calls Presented',
          visible: true
        },
        {
          key: 'taskshandled',
          label: 'Calls Handled',
          visible: true
        },
        {
          key: 'tasksabandoned',
          label: 'Calls Abandoned',
          visible: true
        },
        {
          key: 'tasksshort',
          label: 'Calls Short',
          visible: true
        },
        {
          key: 'taskstotal',
          label: 'Calls Total',
          visible: true
        },
        {
          key: 'avgasa',
          label: 'ASA',
          formatter: this.formatSec,
          visible: false
        },
        {
          key: 'avghandletime',
          label: 'Avg TalkTime',
          formatter: this.formatSec,
          visible: true
        },
        {
          key: 'avgenqueuetime',
          label: 'Avg WaitTime',
          formatter: this.formatSec,
          visible: true
        },
        {
          key: 'maxwait',
          label: 'Max WaitTime',
          formatter: this.formatSec,
          visible: false
        },
        {
          key: 'gos',
          label: 'Grade of Service',
          tdClass: this.gosClass,
          formatter: this.formatGos,
          visible: true
        }
      ]
    }
  },
  filters: {
    formatSec (sec) {
      if (sec >= 3600) return new Date(sec * 1000).toISOString().substring(11, 19)
      return new Date(sec * 1000).toISOString().substring(14, 19)
    }
  },
  methods: {
    formatSec (sec) {
      if (sec >= 3600) return new Date(sec * 1000).toISOString().substring(11, 19)
      return new Date(sec * 1000).toISOString().substring(14, 19)
    },
    formatGos (gos) {
      return `${gos}%`
    },
    availableAgentsClass (value) {
      if (value > 1) return ''
      if (value > 0) return 'atea-orange'
      if (value === 0) return 'atea-red'
      return ''
    },
    callswaitingClass (value, key, item) {
      if (value > this.cwvalueurgent) return 'cw-atea-red-orange'
      if (value > this.cwvaluewarning) return 'cw-atea-red'
      if (value > this.cwvalueinfo) return 'cw-atea-orange'
      return ''
    },
    longestwaitingClass (value, key, item) {
      if (value >= this.lwvalueurgent) return 'lw-atea-red-orange'
      if (value >= this.lwvaluewarning) return 'lw-atea-red'
      else if (value > this.lwvalueinfo) return 'lw-atea-orange'
      return ''
    },
    gosClass (gos) {
      if (gos < this.gosvalueurgent) return 'gos-atea-red-orange'
      else if (gos < this.gosvaluewarning) return 'gos-atea-red'
      else if (gos < this.gosvalueinfo) return 'gos-atea-orange'
      return ''
    },
    queueNameClass () {
      if (this.queueNameAlign) {
        return `queue-${this.queueNameAlign}-align`
      }
      return ''
    }
  },
  computed: {
    ...mapGetters({
      queues: 'wxcc/queuesSUM',
      queueSort: 'wxcc/queueSort',
      queueSortDirection: 'wxcc/queueSortDirection',
      queueTableColumns: 'wxcc/queueTableColumns',
      queueNameAlign: 'wxcc/queueNameAlign',
      cwvalueinfo: 'wxcc/cwvalueinfo',
      cwvaluewarning: 'wxcc/cwvaluewarning',
      cwvalueurgent: 'wxcc/cwvalueurgent',
      lwvalueinfo: 'wxcc/lwvalueinfo',
      lwvaluewarning: 'wxcc/lwvaluewarning',
      lwvalueurgent: 'wxcc/lwvalueurgent',
      gosvalueinfo: 'wxcc/gosvalueinfo',
      gosvaluewarning: 'wxcc/gosvaluewarning',
      gosvalueurgent: 'wxcc/gosvalueurgent'
    }),
    sortedQueues () {
      const q = this.queues
      q.sort((a, b) => {
        if (this.queueSortDirection === 'asc') {
          if (a[this.queueSort] < b[this.queueSort]) return -1
          if (a[this.queueSort] > b[this.queueSort]) return 1
        } else {
          if (a[this.queueSort] > b[this.queueSort]) return -1
          if (a[this.queueSort] < b[this.queueSort]) return 1
        }
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })
      return q
    },
    visibleFields () {
      let showAv = true
      if (this.queues) {
        const numQ = this.queues.reduce((acc, val) => {
        // acc = acc + val.callswaiting
          if (val.routingtype !== 'SKILLS_BASED') acc++
          return acc
        }, 0)
        showAv = numQ > 0
      }

      const myfields = this.fields.map(e => {
        if (e.key === 'availableagents') {
          e.visible = showAv
        }
        // console.log(`checking [${e.key}] in [${this.queueTableColumns}]`)
        if (e.key !== 'name' && !this.queueTableColumns.includes(e.key)) {
          e.visible = false
        }
        if (e.key === 'name' || this.queueTableColumns.includes(e.key)) {
          e.visible = true
        }
        return e
      })
      // console.log('myfields', myfields)
      return myfields.filter(el => el.visible)
    }
    // showAvailable () {
    //   const numQ = this.queues.reduce((acc, val) => {
    //     // acc = acc + val.callswaiting
    //     if (val.routingtype !== 'SKILLS_BASED') acc++
    //     return acc
    //   }, 0)
    //   return numQ > 0
    // }
  }
}
</script>
<style>
.queuesDiv > table > thead > tr > th {
  background-color: #32383E;
  /* font-weight: normal; */
}
.cy-table3 table, thead, tr, th, td {
  border: 1px solid #32383E !important;
}
.queuesDiv  {
  padding: 10px;
}
.atea-orange {
  color: 'orange';
}
.atea-red {
  color: 'red';
}
.atea-red-orange {
  color: 'red';
  background-color: '#FAAC58';
}

.cw-atea-orange {
  color: var(--font-color-info);
  background-color: var(--background-color-info);
}
.cw-atea-red {
  color: var(--font-color-warning);
  background-color: var(--background-color-warning);
}
.cw-atea-red-orange {
  color: var(--font-color-urgent);
  background-color: var(--background-color-urgent);
}

.lw-atea-orange {
  color: var(--lw-font-color-info);
  background-color: var(--lw-background-color-info);
}
.lw-atea-red {
  color: var(--lw-font-color-warning);
  background-color: var(--lw-background-color-warning);
}
.lw-atea-red-orange {
  color: var(--lw-font-color-urgent);
  background-color: var(--lw-background-color-urgent);
}

.gos-atea-orange {
  color: var(--gos-font-color-info);
  background-color: var(--gos-background-color-info);
}
.gos-atea-red {
  color: var(--gos-font-color-warning);
  background-color: var(--gos-background-color-warning);
}
.gos-atea-red-orange {
  color: var(--gos-font-color-urgent);
  background-color: var(--gos-background-color-urgent);
}

.queue-left-align {
  text-align: left;
}
.queue-right-align {
  text-align: right;
}
.queue-center-align {
  text-align: center;
}
</style>
