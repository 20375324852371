<template>
<div id="page-content-wrapper" class="doscroll">
  <Header :showBack="true" :title="'Edit Dashboard ['+dashId+'] '+dashboardName"/>
  <!-- <div class="bg-light page-header-wrapper border-bottom" style="height: 60px; padding: 0.875rem 1.25rem; font-size: 1.2rem;">
    <div style="float: left;">
    Edit Dashboard {{dashId}}
    </div>
  </div> -->
  <div class="bv-example-row text-left pl-4 pt-4">
    <b-form-row>
      <b-col cols="3">
        <b-form-group
          id="fieldset-horizontal"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Dashboard Name"
          label-for="dashboardName"
        >
        <b-input-group>
        <b-form-input v-model="dashboardName" id="dashboardName"></b-form-input>
        <b-input-group-append>
          <b-button @click.prevent="saveDashboardName" size="sm" variant="warning"><font-awesome-icon icon='save'/> Save</b-button>
        </b-input-group-append>
        </b-input-group>
        </b-form-group>
        QueueGroups <b-button size="sm" v-b-modal.csqg-modal>+ Add Queue Group</b-button> <span class="font-italic">(telephony only)</span>
        <b-list-group class="pt-2 pb-4">
          <b-list-group-item v-for="queueGroup in queuegroups" :key="queueGroup" class="bg-light">
            <b-button @click.prevent="deleteQueueGroupFromDashoard(queueGroup)" size="sm" variant="danger"><font-awesome-icon icon='trash-alt'/></b-button>
            {{allQueueGroups[queueGroup].desc}} [{{allQueueGroups[queueGroup].queues.join(', ')}}]
          </b-list-group-item>
        </b-list-group>
        Queues <b-button size="sm" v-b-modal.csq-modal>+ Add Queue</b-button>
        <b-spinner v-if="loading" label="Loading..."></b-spinner>
        <b-list-group class="pt-2">
          <b-list-group-item v-for="queue in queues" :key="queue" class="bg-light">
            <b-button @click.prevent="deleteQueueFromDashoard(queue)" size="sm" variant="danger"><font-awesome-icon icon='trash-alt'/></b-button>
            {{allQueues[queue]}}
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-form-row>
  </div>
  <b-modal
    id="csq-modal"
    title="Add Queue"
    header-text-variant="primary"
    class="text-dark"
    size="lg"
  >
    <b-input-group>
    <b-form-input v-model="queuefilter" placeholder="Search"></b-form-input>
    <b-input-group-append>
      <b-input-group-text>
        <font-awesome-icon icon="times-circle" @click="queuefilter = ''"/>
      </b-input-group-text>
    </b-input-group-append>
    </b-input-group>
    <b-list-group class="text-dark">
          <b-list-group-item v-for="queue in freeQueues" :key="queue" class="bg-light">
            <b-button @click.prevent="addQueueToDashoard(queue)" size="sm" variant="success">+</b-button>
            {{allQueues[queue]}}
          </b-list-group-item>
        </b-list-group>
  </b-modal>
  <b-modal
    id="csqg-modal"
    title="Add QueueGroup"
    header-text-variant="primary"
    class="text-dark"
    size="lg"
  >
    <b-list-group class="text-dark">
          <b-list-group-item v-for="queuegroup in freeGroups" :key="queuegroup" class="bg-light">
            <b-button @click.prevent="addQueueGroupToDashoard(queuegroup)" size="sm" variant="success">+</b-button>
            {{allQueueGroups[queuegroup].desc}} [{{allQueueGroups[queuegroup].queues.join(', ')}}]
          </b-list-group-item>
        </b-list-group>
  </b-modal>
</div>
</template>

<script>
import GraphqlService from '../service/GraphqlService'
import Header from '@/modules/shared/components/elements/Header'

export default {
  data () {
    return {
      dashId: '',
      dashboardName: '',
      queues: [],
      allQueues: {},
      allQueueIds: [],
      loading: true,
      allQueueGroups: {},
      allQueueGroupIds: [],
      queuegroups: [],
      queuefilter: ''
    }
  },
  async mounted () {
    this.dashId = this.$route.params.id
    const allqueues = await GraphqlService.getAlQueues(this.$store)
    this.allQueues = allqueues.reduce((prev, curr) => {
      prev[curr.id] = `${curr.name} [${curr.channelType.toLowerCase()} - ${curr.queueType.toLowerCase()}]`
      return prev
    }, {})
    allqueues.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      if (b.name.toLowerCase() > a.name.toLowerCase()) return -1
      return 0
    })
    allqueues.forEach(q => {
      this.allQueueIds.push(q.id)
    })
    const allqueuegroups = await GraphqlService.getQueueGroups(this.$store, '*', true)
    console.log('allqueuegroups', allqueuegroups)
    this.allQueueGroups = allqueuegroups.reduce((prev, curr) => {
      prev[curr.id] = {
        desc: `${curr.name}`,
        queues: curr.queueids.reduce((p, c) => {
          p.push(`${this.allQueues[c].replace(/\s*\[.*?\]\s*/, '')}`)
          return p
        }, [])
      }
      return prev
    }, {})
    allqueuegroups.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      if (b.name.toLowerCase() > a.name.toLowerCase()) return -1
      return 0
    })
    allqueuegroups.forEach(q => {
      this.allQueueGroupIds.push(q.id)
    })
    this.loading = false
    const dash = await GraphqlService.getDashboard(this.$store, this.dashId, true)
    this.dashboardName = dash.name
    this.queues = dash.queues
    this.queuegroups = dash.groupids
  },
  beforeDestroy () {
    console.log('beforeDestroy')
    GraphqlService.syncDashboardConfig(this.$store)
  },
  computed: {
    freeQueues () {
      return this.allQueueIds.filter(e => {
        if (this.allQueues[e].toUpperCase().includes(this.queuefilter.toUpperCase()) && !this.queues.includes(e)) return true
      })
    },
    freeGroups () {
      return this.allQueueGroupIds.filter(e => {
        if (!this.queuegroups.includes(e)) return true
      })
    }
  },
  methods: {
    async saveDashboardName () {
      try {
        const dash = await GraphqlService.saveDashboardName(this.$store, this.dashId, this.dashboardName)
        console.dir(dash)
        if (dash.id !== this.dashId) {
          this.$store.dispatch('global/DO_TOAST', { message: 'Error Saving Dashboard', variant: 'danger' })
        } else {
          this.$store.dispatch('global/DO_TOAST', { message: 'Saved Dashboard', variant: 'success' })
        }
      } catch (err) {
        console.error('error', err)
        this.$store.dispatch('global/DO_TOAST', { message: 'Error Saving Dashboard', variant: 'danger' })
      }
    },
    async addQueueToDashoard (queueId) {
      try {
        const dash = await GraphqlService.addQueueToDashboard(this.$store, this.dashId, queueId)
        console.dir(dash)
        if (dash.id !== this.dashId) {
          this.$store.dispatch('global/DO_TOAST', { message: 'Error Adding Queue', variant: 'danger' })
        } else {
          this.queues.push(queueId)
          this.$store.dispatch('global/DO_TOAST', { message: 'Added Queue', variant: 'success' })
        }
      } catch (err) {
        console.error('error', err)
        this.$store.dispatch('global/DO_TOAST', { message: 'Error Adding Queue', variant: 'danger' })
      }
    },
    async addQueueGroupToDashoard (queueGroupId) {
      try {
        const dash = await GraphqlService.addQueueGroupToDashboard(this.$store, this.dashId, queueGroupId)
        console.dir(dash)
        if (dash.id !== this.dashId) {
          this.$store.dispatch('global/DO_TOAST', { message: 'Error Adding QueueGroup', variant: 'danger' })
        } else {
          this.queuegroups.push(queueGroupId)
          this.$store.dispatch('global/DO_TOAST', { message: 'Added QueueGroup', variant: 'success' })
        }
      } catch (err) {
        console.error('error', err)
        this.$store.dispatch('global/DO_TOAST', { message: 'Error Adding QueueGroup', variant: 'danger' })
      }
    },
    async deleteQueueFromDashoard (queueId) {
      this.$bvModal.msgBoxConfirm('Remove Queue?', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        headerClass: 'text-danger',
        bodyClass: 'text-dark',
        hideHeaderClose: false,
        centered: true
      })
        .then(async value => {
          if (value) {
            const dash = await GraphqlService.removeDashboardQueue(this.$store, this.dashId, queueId)
            if (dash.id !== this.dashId) {
              console.error('error deleting')
              this.$store.dispatch('global/DO_TOAST', { message: 'Error Removing Queue', variant: 'danger' })
            } else {
              this.$store.dispatch('global/DO_TOAST', { message: 'Removed Queue', variant: 'success' })
              const dash = await GraphqlService.getDashboard(this.$store, this.dashId, true)
              this.dashboardName = dash.name
              this.queues = dash.queues
              // this.getDashboards()
            }
          }
        })
        .catch(err => {
          console.error(err)
          this.$store.dispatch('global/DO_TOAST', { message: `Error Removing Queue ${err}`, variant: 'danger' })
        })
    },
    async deleteQueueGroupFromDashoard (queueGroupId) {
      this.$bvModal.msgBoxConfirm('Remove QueueGroup?', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        headerClass: 'text-danger',
        bodyClass: 'text-dark',
        hideHeaderClose: false,
        centered: true
      })
        .then(async value => {
          if (value) {
            const dash = await GraphqlService.removeQueueGroupFromDashboard(this.$store, this.dashId, queueGroupId)
            if (dash.id !== this.dashId) {
              console.error('error deleting')
              this.$store.dispatch('global/DO_TOAST', { message: 'Error Removing Queue', variant: 'danger' })
            } else {
              this.$store.dispatch('global/DO_TOAST', { message: 'Removed Queue', variant: 'success' })
              const dash = await GraphqlService.getDashboard(this.$store, this.dashId, true)
              this.dashboardName = dash.name
              this.queues = dash.queues
              this.queuegroups = dash.groupids
              // this.getDashboards()
            }
          }
        })
        .catch(err => {
          console.error(err)
          this.$store.dispatch('global/DO_TOAST', { message: `Error Removing Queue ${err}`, variant: 'danger' })
        })
    },
    getQueueName (groupid) {
      return 'test'
    }
  },
  components: {
    Header
  }
}
</script>
<style scoped>
#page-content-wrapper {
  background-color: white;
  color: black;
}
.doscroll {
  overflow: auto;
}
</style>
