<template>
  <div id="totalCalls" :class="totalcallsClass">{{totalCalls}}</div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      queues: 'wxcc/queues'
    }),
    totalCalls () {
      return this.queues.reduce((acc, val) => {
        acc = acc + val.taskshandled
        return acc
      }, 0)
    },
    totalcallsClass () {
      return ''
    }
  }
}
</script>
<style scoped>

</style>
